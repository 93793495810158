@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;

@mixin theme($theme) {
  // doesn't use theme defined colors at the moment, pulls from another palette color
  .mat-mdc-list-base {
    .mat-mdc-list-item,
    .mat-mdc-list-option {
      font-size: 1rem;

      &:nth-of-type(odd) {
        background-color: mat.m2-get-color-from-palette(gl.$surface, 500);
      }

      &:nth-of-type(even) {
        background-color: mat.m2-get-color-from-palette(gl.$surface, 400);
      }

      &.oc-link:hover {
        background-color: mat.m2-get-color-from-palette(gl.$surface, 300);
        cursor: pointer;
      }

      &.oc-selected,
      &.oc-warning {
        background-color: mat.m2-get-color-from-palette(gl.$surface, 700);

        .mat-list-item-content::before {
          @include util.status-border;
        }
      }

      &.oc-selected .mat-list-item-content::before {
        background-color: mat.m2-get-color-from-palette(gl.$seafoam);
      }

      &.oc-warning .mat-list-item-content::before {
        background-color: gl.$warning;
      }
    }
  }

  .mat-list-base[dense].oc-plain {
    .mat-list-item,
    .mat-list-option {
      background-color: transparent;
      font-size: 1rem;

      .mat-list-item-content {
        padding: 0;
      }
    }
  }
}
