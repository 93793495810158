@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  .gl-patient-banner {
    @apply tw-bg-surface-default;
  }
}
