@use 'sass:map';
@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  a:not(.mat-mdc-button-base, .mat-mdc-tab-link),
  a:visited:not(.mat-mdc-button-base, .mat-mdc-tab-link),
  a:hover:not(.mat-mdc-button-base, .mat-mdc-tab-link),
  a:active:not(.mat-mdc-button-base, .mat-mdc-tab-link) {
    @apply tw-text-link tw-underline;
  }
}
