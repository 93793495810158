@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $config: mat.m2-get-color-config($theme);
  $foreground: map.get($config, foreground);
  $divider-color: mat.m2-get-color-from-palette($foreground, divider);

  // Shared styles between desktop and kiosk
  .mat-mdc-table {
    @apply tw-w-full tw-table-auto;

    td.mat-mdc-cell.gl-cell-single-line {
      @apply tw-align-middle;
    }

    // Wrapped text aligns center by default, should align left
    .mat-sort-header-content {
      text-align: left;
    }

    // Link Behavior
    > .mat-mdc-row.oc-link:hover,
    tbody > .mat-mdc-row.oc-link:hover {
      @apply tw-cursor-pointer;
    }

    // Disabled row
    .oc-disabled {
      opacity: 0.5;
    }

    // Right alignment for headers and columns
    .oc-align-right {
      @apply tw-text-right;

      .mat-mdc-sort-header-container {
        @apply tw-flex-row-reverse  tw-justify-start;
      }
    }

    .oc-align-center {
      @apply tw-text-center;
    }
  }

  // Sticky header with filters
  [data-theme='desktop-dark'] .gl-sticky-header-filters {
    @apply tw-sticky tw-top-24 tw-z-10;
  }

  .gl-sticky-header {
    @apply tw-sticky tw-top-0 tw-z-10;
  }

  // Desktop only styles
  [data-theme='desktop-dark'] .mat-mdc-table {
    td.mat-mdc-cell {
      @apply tw-px-6 tw-py-3;
    }

    // Override opacity, set table text to white
    .mdc-data-table__pagination-total,
    .mdc-data-table__pagination-rows-per-page-label,
    .mdc-data-table__cell {
      @apply tw-text-surface-contrast-100;
    }

    .mat-form-field-wrapper {
      @apply tw-px-0 tw-py-1;
    }

    // Form fields in table
    td.mat-mdc-cell.gl-cell-form-field {
      @apply tw-pb-0.5 tw-pt-6;
    }

    // Body rows
    > .mat-mdc-row,
    tbody > .mat-mdc-row {
      background-color: mat.m2-get-color-from-palette(gl.$surface, 400);

      td.mat-mdc-cell {
        @apply tw-h-auto tw-border-b tw-border-solid;
        @include util.color-border;
      }
    }

    // Header row
    thead > .mat-mdc-header-row {
      background-color: mat.m2-get-color-from-palette(gl.$surface, 500);

      .mat-mdc-header-cell {
        @apply tw-h-auto tw-border-b tw-px-6 tw-text-sm tw-font-bold tw-text-neutral-100;
        @include util.color-border-strong;

        line-height: 1.125rem;
      }
    }

    // Link background color on hover
    > .mat-mdc-row.oc-link:hover,
    tbody > .mat-mdc-row.oc-link:hover {
      @apply tw-bg-surface-300;
    }

    // Right aligned arrow alignment
    .oc-align-right .mat-sort-header-arrow,
    .mat-sort-header-position-before .mat-sort-header-arrow,
    [dir='rtl'] .mat-sort-header-arrow {
      margin: 0 0.375rem 0 1rem;
    }

    // Adjust margins on the sort arrow so it doesn't bump against the column next to it
    .mat-sort-header-arrow {
      margin: 0 1rem 0 0.375rem;
    }

    .oc-align-center {
      @apply tw-text-center;

      .mat-sort-header-container {
        @apply tw-flex tw-justify-center;
      }
    }

    // Deprecated start
    > .mat-mdc-row.oc-selected,
    tbody > .mat-mdc-row.oc-selected,
    > .mat-mdc-row.oc-warning,
    tbody > .mat-mdc-row.oc-warning {
      background-color: mat.m2-get-color-from-palette(gl.$surface, 700);
      position: relative;

      .mat-mdc-cell:first-child::before {
        @include util.status-border;
      }
    }

    > .mat-mdc-row.oc-selected,
    tbody > .mat-mdc-row.oc-selected {
      .mat-mdc-cell:first-child::before {
        background-color: mat.m2-get-color-from-palette(gl.$seafoam);
      }
    }

    > .mat-mdc-row.oc-warning,
    tbody > .mat-mdc-row.oc-warning {
      .mat-mdc-cell:first-child::before {
        background-color: gl.$warning;
      }
    }

    // Deprecated end

    // Flagged row
    > .mat-mdc-row.gl-flagged-row,
    tbody > .mat-mdc-row.gl-flagged-row {
      @apply tw-relative tw-overflow-hidden tw-bg-surface-400;

      .mat-mdc-cell:first-child::before {
        @apply tw-absolute tw-inset-0 tw-h-0 tw-w-0 tw-border-solid;

        content: '';
        border-color: mat.m2-get-color-from-palette($primary) transparent;
        border-width: 16px 16px 0 0;
      }
    }

    // Strike through cell or row
    .gl-strike-through {
      @apply tw-line-through;
    }

    // Select column in table
    .mat-mdc-cell.mat-column-select {
      @apply tw-w-16 tw-px-3;
    }

    th.mat-mdc-header-cell.mat-column-select {
      @apply tw-px-3;
    }

    // Actions column in table
    td.mat-mdc-cell.mat-column-actions {
      @apply tw-w-40 tw-px-2 tw-py-0 tw-align-middle;

      &,
      .mat-mdc-sort-header-content {
        @apply tw-text-right;
      }
    }

    th.mat-mdc-header-cell.mat-column-actions {
      @apply tw-px-2 tw-py-0 tw-align-middle;

      &,
      .mat-mdc-sort-header-content {
        @apply tw-pr-6 tw-text-right;
      }
    }

    // Row expansion
    td.mat-mdc-cell.mat-column-expansion {
      padding: 0;
    }

    // Expanded row
    td.mat-mdc-cell.oc-expanded {
      background-color: mat.m2-get-color-from-palette(gl.$surface, 500);
      @apply tw-px-16 tw-py-6;

      box-shadow: 0 4px 12px 0 rgb(0 0 0 / 6%) inset;
    }
  }

  // Desktop Paginator
  [data-theme='desktop-dark'] .mat-mdc-paginator {
    @apply tw-rounded-b tw-bg-surface-500;
  }

  // Desktop Dialog table
  [data-theme='desktop-dark'] .mat-dialog-content .mat-mdc-table {
    border: 2px solid mat.m2-get-color-from-palette(gl.$surface, 300);
  }

  // Desktop Header groups
  [data-theme='desktop-dark'] tr.mat-mdc-header-row.oc-dual-header {
    @apply tw-h-auto;

    th.mat-mdc-header-cell {
      @apply tw-border-0 tw-py-3;
    }

    th.mat-mdc-header-cell.oc-dual-header-cell {
      @apply tw-relative tw-text-center;

      &::after {
        @apply tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto tw-h-0 tw-border-b;

        border-color: mat.m2-get-color-from-palette($foreground, secondary-text);
        content: '';
        width: 95%;
      }
    }
  }

  // Desktop Table Filters
  [data-theme='desktop-dark'] gl-table-filters {
    .gl-table-filters-container {
      @apply tw-absolute tw-flex tw-h-24 tw-w-full tw-rounded-t;
    }

    .gl-action-button {
      @apply tw-inline-flex tw-h-24 tw-items-center tw-gap-x-2 tw-px-6 tw-font-bold;

      border-left: 1px solid rgb(0 0 0 / 30%);
    }

    .gl-action-button:disabled {
      opacity: 0.5;
    }

    .gl-action-button:not(:disabled) {
      &:hover {
        @apply tw-bg-surface-400;
      }
    }

    .gl-arrow-down {
      @apply tw-absolute tw-z-10 tw-ml-6 tw-mt-19 tw-h-0 tw-w-0 tw-border-x-8 tw-border-x-transparent;

      border-top: 8px solid mat.m2-get-color-from-palette(gl.$surface, 300);
    }

    .gl-bottom-border {
      @apply tw-border-b;
      @include util.color-border;
    }

    .gl-side-border {
      border-right: 1px solid rgb(0 0 0 / 50%);
    }

    gl-search {
      .mat-mdc-form-field {
        @apply tw-pt-6;
      }
    }

    .mat-button-toggle-group {
      @apply tw-gap-2.5 tw-rounded-none tw-border-0;

      .mat-button-toggle {
        @apply tw-rounded;

        border: 1px solid $divider-color;

        .mat-button-toggle-label-content {
          @apply tw-leading-10;
        }
      }
    }
  }

  // Kiosk Styles
  [data-theme='kiosk-light'] .mat-mdc-table,
  [data-theme='kiosk-dark'] .mat-mdc-table {
    --mat-table-background-color: rgb(var(--bg-surface-flat));

    // Border around empty table
    .mat-mdc-no-data-row > td {
      @apply tw-rounded-b-3xl tw-border-x tw-border-b tw-border-solid tw-border-default;
    }

    // Border around table
    @apply tw-border-separate tw-overflow-clip;

    // Hide table border behind sticky header when scrolling
    thead th:first-child::before,
    thead th:last-child::after {
      @apply tw-absolute tw-top-0 tw-block tw-w-px tw-bg-surface-flat;

      height: 1.125rem;
      content: '';
    }

    thead th:first-child::before {
      @apply tw-left-0;
    }

    thead th:last-child::after {
      @apply tw-right-0;
    }

    // Header row
    .mat-mdc-header-row {
      @apply oc-text-body-strong tw-bg-surface-flat tw-text-primary;

      // Header cells
      .mat-mdc-header-cell {
        @apply tw-border-t tw-border-solid tw-border-default tw-bg-surface-inset tw-px-4 tw-py-3;

        --mat-table-row-item-outline-color: rgb(var(--border-strong));
      }

      > :first-child {
        @apply tw-rounded-tl-3xl tw-border-l tw-border-solid tw-border-default;
      }

      > :last-child {
        @apply tw-rounded-tr-3xl tw-border-r tw-border-solid tw-border-default;
      }
    }

    // Last body row
    tbody .mat-mdc-row:last-child {
      > :first-child {
        @apply tw-rounded-bl-3xl tw-border-l tw-border-solid tw-border-default;
      }

      > :last-child {
        @apply tw-rounded-br-3xl tw-border-r tw-border-solid tw-border-default;
      }

      .mat-mdc-cell {
        @apply tw-border-b tw-border-solid tw-border-default;
      }
    }

    // Body Rows
    .mat-mdc-row {
      > :first-child {
        @apply tw-border-l tw-border-solid tw-border-default;
      }

      > :last-child {
        @apply tw-border-r tw-border-solid tw-border-default;
      }

      --mat-table-row-item-label-text-color: rbg(var(--text-primary));

      .mat-mdc-cell {
        @apply oc-text-body tw-border-default tw-px-4 tw-py-4;
      }
    }

    // Mat sort header arrow
    .mat-sort-header-container {
      .mat-sort-header-arrow {
        // Using important because this is defined as inline styles in material
        transform: scale(1.8) !important;

        .mat-sort-header-pointer-middle,
        .mat-sort-header-stem {
          @apply tw-w-px;
        }

        .mat-sort-header-pointer-middle,
        .mat-sort-header-pointer-left,
        .mat-sort-header-pointer-right {
          @apply tw-h-px;
        }

        .mat-sort-header-indicator {
          @apply tw-h-0;
        }
      }
    }

    .mat-sort-header-arrow {
      margin: 0 0 0 1rem;
    }

    // Right aligned arrow alignment
    .oc-align-right .mat-sort-header-arrow,
    .mat-sort-header-position-before .mat-sort-header-arrow,
    [dir='rtl'] .mat-sort-header-arrow {
      margin: 0 1rem 0 0;
    }

    // Select column in table
    .mat-mdc-cell.mat-column-select {
      @apply tw-w-11 tw-py-0 tw-pr-0;
    }

    th.mat-mdc-header-cell.mat-column-select {
      @apply tw-w-11 tw-py-0 tw-pr-0;
    }
  }
}
