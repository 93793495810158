@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  .gl-text-block-more {
    .gl-tag {
      @apply tw-ml-0;
    }
  }
}
