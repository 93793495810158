@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  gl-icon {
    .mat-icon {
      // set icon size in relation to body text
      display: inline-block;
      width: var(--body-line-height);
      height: var(--body-line-height);
      font-size: var(--body-line-height);
    }

    display: inline-flex;
  }

  [data-theme='desktop-dark'] gl-icon,
  [data-theme='desktop-dark'] .mat-mdc-form-field-icon-suffix,
  [data-theme='desktop-dark'] .mat-mdc-form-field-icon-prefix {
    .gl-icon-success {
      @apply tw-text-success;
    }

    .gl-icon-warning {
      @apply tw-text-warning;
    }

    .gl-icon-critical {
      @apply tw-text-critical;
    }

    .gl-icon-info {
      @apply tw-text-azure;
    }

    .gl-icon-warning-strong {
      @apply tw-text-warning-strong;
    }
  }

  [data-theme='kiosk-light'],
  [data-theme='kiosk-dark'] {
    gl-icon,
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
      .gl-icon-default {
        @apply tw-text-decor-neutral;
      }

      .gl-icon-success {
        @apply tw-text-decor-success;
      }

      .gl-icon-warning {
        @apply tw-text-decor-warning;
      }

      .gl-icon-critical {
        @apply tw-text-decor-critical;
      }

      .gl-icon-info {
        @apply tw-text-decor-primary;
      }

      .gl-icon-warning-strong {
        @apply tw-text-warning-strong;
      }
    }
  }
}
