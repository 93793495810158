@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);

  [data-theme='desktop-dark'] rs-scheduler-day-toggle-button {
    .oc-scheduler-day-toggle-button {
      background-color: mat.m2-get-color-from-palette(gl.$neutral, 100);
      color: mat.m2-get-color-from-palette(gl.$neutral, 900);
      @apply tw-m-1.5;
    }

    .mat-button-toggle {
      @apply tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-p-0;
    }

    .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      background-color: mat.m2-get-color-from-palette(gl.$azure, 500);
      color: mat.m2-get-color-from-palette(gl.$azure, A100);
    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      @apply tw-p-0 tw-leading-normal;
    }
  }

  [data-theme='kiosk-light'] rs-scheduler-day-toggle-button,
  [data-theme='kiosk-dark'] rs-scheduler-day-toggle-button {
    .oc-scheduler-day-toggle-button {
      background-color: mat.m2-get-color-from-palette(gl.$neutral, 50);
      color: mat.m2-get-color-from-palette(gl.$surface, 900);
      @apply tw-m-2;
    }

    .mat-button-toggle {
      @apply tw-flex tw-h-13 tw-w-13 tw-items-center tw-justify-center tw-rounded-full  tw-p-0;
    }

    .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      @apply tw-bg-primary tw-text-on-primary;
    }

    .mat-button-toggle-appearance-standard {
      @apply tw-text-4xl tw-font-normal;
    }
  }
}
