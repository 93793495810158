@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities/' as util;
@use 'sass:map';

@mixin theme($theme) {
  .mdc-menu-surface.mat-mdc-select-panel.oc-panel-full,
  .mdc-menu-surface.mat-mdc-select-panel.gl-panel-full {
    max-height: unset;
  }

  // remove checkbox from clear selected option
  .gl-clear-selected > .mat-pseudo-checkbox {
    display: none;
  }

  // override disabled opacity for clear selected
  .mat-mdc-option.mdc-list-item--disabled.gl-clear-selected {
    opacity: 1;
  }

  .mat-mdc-select {
    @apply oc-text-body;
  }

  [data-theme] {
    // all themes, to override MDC specificity for things without gl classes
    .mat-mdc-select-panel {
      @include util.elevation-popover;

      --mdc-list-list-item-label-text-color: rgb(var(--text-primary));

      .mat-mdc-optgroup-label {
        @apply tw-text-base;
      }
    }
  }

  [data-theme='desktop-dark']gl-select {
    .mat-select-search-input {
      @apply tw-bg-surface-300;
    }
  }

  [data-theme='kiosk-light'] gl-select,
  [data-theme='kiosk-dark'] gl-select {
    // equivalent to tw-text-secondary
    --mat-select-enabled-arrow-color: rgb(var(--neutral-alt-600));

    // equivalent to tw-text-focus
    --mat-select-focused-arrow-color: rgb(var(--blue-600));

    // equivalent to tw-text-critical
    --mat-select-invalid-arrow-color: rgb(var(--red-600));

    // equivalent to tw-text-disabled
    --mat-select-disabled-trigger-text-color: rgb(var(--neutral-alt-300));
  }

  // rounded panel overlay
  [data-theme='kiosk-light'] .cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel,
  [data-theme='kiosk-dark'] .cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
    @apply tw-rounded-2xl;
  }

  // elements in panel render outside gl-select
  [data-theme='kiosk-light'] .mdc-menu-surface.mat-mdc-select-panel,
  [data-theme='kiosk-dark'] .mdc-menu-surface.mat-mdc-select-panel {
    // equivalent to tw-bg-surface-hover
    --mat-option-selected-state-layer-color: rgb(var(--green-67));
    --mat-option-hover-state-layer-color: rgb(var(--green-67));

    // equivalent to tw-text-brand-secondary
    --mat-option-selected-state-label-text-color: rgb(var(--green-600));
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: rgb(var(--green-600));

    @apply tw-rounded-2xl;

    max-height: 500px;
    max-width: 420px;

    .mat-select-search-input {
      @apply tw-h-11 tw-border-b tw-border-default tw-bg-surface-popover tw-text-primary;

      &::placeholder {
        @apply tw-text-secondary;
      }
    }

    .mat-mdc-option {
      @apply oc-text-body tw-px-4 tw-py-3 tw-text-primary;
    }

    .mat-mdc-optgroup-label {
      @apply tw-py-3;

      .mdc-list-item__primary-text {
        @apply oc-text-body-strong tw-text-primary;
      }
    }

    .mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
      @apply tw-pl-3;
    }
  }
}
