@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  .mat-expansion-panel {
    @include util.elevation-flat;

    --mat-expansion-header-text-weight: 400;

    .mat-expansion-panel-header {
      @apply oc-text-body;
    }

    &.oc-selected,
    &.oc-warning {
      .mat-expansion-panel-header::before,
      .mat-expansion-panel-body::before {
        @include util.status-border;
      }
    }

    &.oc-selected .mat-expansion-panel-header::before,
    &.oc-selected .mat-expansion-panel-body::before {
      background-color: mat.m2-get-color-from-palette(gl.$seafoam);
    }

    &.oc-warning .mat-expansion-panel-header::before,
    &.oc-warning .mat-expansion-panel-body::before {
      background-color: gl.$warning;
    }
  }

  // adjustments to account for omnimeta abstraction adding extra DOM element
  .mat-accordion .mat-expansion-panel:first-of-type,
  .mat-accordion .mat-expansion-panel:last-of-type,
  .mat-accordion .mat-expansion-panel {
    border-radius: 0 !important;
  }

  .mat-accordion > .mat-expansion-panel:first-of-type,
  .mat-accordion .gl-expansion-panel:first-of-type .mat-expansion-panel {
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
  }

  .mat-accordion > .mat-expansion-panel:last-of-type,
  .mat-accordion .gl-expansion-panel:last-of-type .mat-expansion-panel {
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .gl-expansion-panel .mat-expansion-panel.mat-expanded,
  .mat-expansion-panel.mat-expanded {
    border-radius: 4px !important;
  }
}
