@use '../utilities/' as util;

@mixin theme($theme) {
  gl-menu {
    @apply tw-inline-flex;
  }

  // can be removed when no mat menu instances exist
  [data-theme='desktop-dark'] {
    .mat-mdc-menu-panel {
      @include util.elevation-popover;
    }
  }

  .gl-menu-panel.mat-mdc-menu-panel {
    @include util.elevation-popover;

    --mat-menu-item-label-text-line-height: var(--body-line-height);
    --mat-menu-item-label-text-size: var(--body-text-size);
    --mat-menu-item-spacing: var(--spacing-2);
    --mat-menu-item-icon-color: rgb(var(--text-primary));
    --mat-menu-item-icon-size: var(--body-line-height);

    min-width: var(--spacing-48);
    max-width: var(--spacing-70);

    .mat-mdc-menu-item .mat-mdc-menu-item-text {
      @apply oc-text-body tw-flex tw-gap-2 tw-text-primary;
    }

    .mat-mdc-menu-content {
      @apply tw-py-3;
    }

    .mat-mdc-menu-item {
      @apply tw-p-4;
    }

    .mat-mdc-menu-submenu-icon {
      @apply tw-pl-0;
    }

    .mdc-button__label {
      @apply tw-text-primary;
    }
  }

  [data-theme='kiosk-light'] .gl-menu-panel,
  [data-theme='kiosk-dark'] .gl-menu-panel {
    &.mat-mdc-menu-panel {
      @apply tw-rounded-2xl;
    }
  }

  [data-theme='kiosk-light'] .gl-menu-primary-text,
  [data-theme='kiosk-dark'] .gl-menu-primary-text {
    .mat-mdc-button-base:not(:disabled) {
      --mat-mdc-button-persistent-ripple-color: rgb(var(--green-900) / 10%);
      --mat-text-button-ripple-color: rgb(var(--green-900) / 10%);
      --mat-text-button-state-layer-color: rgb(var(--green-900) / 10%);
      @apply tw-text-primary;
    }
  }
}
