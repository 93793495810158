@use 'sass:math';
@mixin build($theme) {
  $columns: 16;
  $rows: 6;

  /**
   * Fractional Grid Columns
   * EXPERIMENTAL
   * These are experimental fractional grids and are subject to change formatting
   */
  $two-columns:
    1 2,
    1 3;

  %oc-grid-cols {
    @apply tw-grid tw-grid-cols-1 tw-gap-4;
  }
  %oc-grid-rows {
    @apply tw-grid tw-grid-flow-col tw-grid-rows-1 tw-gap-4;
  }

  .oc-full-col-only-child {
    :only-child {
      @apply tw-col-span-full;
    }
  }

  .oc-grid-cols-base {
    @extend %oc-grid-cols;
  }

  /**
  * Grid of 3 Column with different fractional units
  **/
  .oc-grid-cols-2-1-1 {
    @apply tw-grid-cols-[2fr_1fr_1fr];
  }

  .oc-grid-cols-1-2-1 {
    @apply tw-grid-cols-[1fr_2fr_1fr];
  }

  .oc-grid-cols-1-1-2 {
    @apply tw-grid-cols-[1fr_1fr_2fr];
  }

  .oc-grid-cols-2-2-1 {
    @apply tw-grid-cols-[2fr_2fr_1fr];
  }

  .oc-grid-cols-1-2-2 {
    @apply tw-grid-cols-[1fr_2fr_2fr];
  }

  .oc-grid-cols-3-1-1 {
    @apply tw-grid-cols-[3fr_1fr_1fr];
  }

  .oc-grid-cols-1-3-1 {
    @apply tw-grid-cols-[1fr_3fr_1fr];
  }

  .oc-grid-cols-1-1-3 {
    @apply tw-grid-cols-[1fr_1fr_3fr];
  }

  .oc-grid-cols-3-3-1 {
    @apply tw-grid-cols-[3fr_3fr_1fr];
  }

  .oc-grid-cols-1-3-3 {
    @apply tw-grid-cols-[1fr_3fr_3fr];
  }

  .oc-grid-cols-2-3-4 {
    @apply tw-grid-cols-[2fr_3fr_4fr];
  }

  /**
  * Grid of 4 Column with different fractional units
  **/
  .oc-grid-cols-2-1-1-1 {
    @apply tw-grid-cols-[2fr_1fr_1fr_1fr];
  }

  .oc-grid-cols-1-2-1-1 {
    @apply tw-grid-cols-[1fr_2fr_1fr_1fr];
  }

  .oc-grid-cols-1-1-2-1 {
    @apply tw-grid-cols-[1fr_1fr_2fr_1fr];
  }

  .oc-grid-cols-1-1-1-2 {
    @apply tw-grid-cols-[1fr_1fr_1fr_2fr];
  }

  .oc-grid-cols-3-1-1-1 {
    @apply tw-grid-cols-[3fr_1fr_1fr_1fr];
  }

  .oc-grid-cols-1-3-1-1 {
    @apply tw-grid-cols-[1fr_3fr_1fr_1fr];
  }

  .oc-grid-cols-1-1-3-1 {
    @apply tw-grid-cols-[1fr_1fr_3fr_1fr];
  }

  .oc-grid-cols-1-1-1-3 {
    @apply tw-grid-cols-[1fr_1fr_1fr_3fr];
  }

  /**
   * Standard Grid Columns
   */
  @for $i from 1 through $columns {
    .oc-grid-cols-#{$i} {
      @extend %oc-grid-cols;

      @screen sm {
        @apply tw-grid-cols-#{math.round(math.div($i, 2))};
      }

      @screen md {
        @apply tw-grid-cols-#{$i};
      }

      > * {
        @media (width <= 599px) {
          @apply tw-col-span-1;
        }
      }
    }
  }

  @each $fr1, $fr2 in $two-columns {
    .oc-grid-cols-#{$fr1}-#{$fr2} {
      @extend %oc-grid-cols;

      @screen sm {
        @apply tw-grid-cols-[#{$fr1}fr_#{$fr2}fr];
      }
    }

    .oc-grid-cols-#{$fr2}-#{$fr1} {
      @extend %oc-grid-cols;

      @screen sm {
        @apply tw-grid-cols-[#{$fr2}fr_#{$fr1}fr];
      }
    }
  }

  @for $i from 1 through $rows {
    .oc-grid-rows-#{$i} {
      @extend %oc-grid-rows;
      @screen xs {
        @apply tw-grid-flow-row tw-grid-rows-none;
      }

      > * {
        @media (width <= 599px) {
          @apply tw-col-span-1;
        }
      }

      @screen sm {
        @apply tw-grid-rows-#{$i * 2};
        @apply tw-grid-flow-col;
      }

      @screen md {
        @apply tw-grid-rows-#{$i};
      }
    }
  }
}
