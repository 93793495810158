@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  gl-floating-nav-bar {
    .gl-visible {
      visibility: visible;
      opacity: 1;
      transition: opacity 100ms linear;
    }

    .gl-hidden {
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0s 100ms,
        opacity 100ms linear;
    }

    .gl-tabs-container {
      @apply tw-fixed tw-bottom-4 tw-left-1/2 tw-z-350 tw-w-full tw-px-6;

      transform: translateX(-50%);
    }

    .gl-tabs {
      @apply tw-flex tw-w-full tw-flex-row tw-gap-1.5 tw-rounded-full tw-bg-ui-secondary tw-p-3 tw-text-on-success-strong;

      // heading 4
      font-size: 2.25rem;
      line-height: 3rem;
      font-weight: 500;
    }

    .gl-tab {
      @apply tw-flex tw-flex-grow tw-items-center tw-justify-center tw-rounded-full tw-px-8 tw-py-4;

      // Remove after ASHP for keyboard accessibility
      &:focus-visible {
        outline: none;
      }
    }

    .gl-tab.gl-active {
      @apply tw-bg-secondary;

      box-shadow: 0 16px 16px 0 rgb(0 0 0 / 6%) inset;
    }
  }
}
