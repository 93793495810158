@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  gl-scroll-container {
    .gl-vertical-scroll-container {
      // sticky tab headers for vertical scroll
      .mat-mdc-tab-group {
        @apply tw-h-full;

        .mat-mdc-tab-header {
          @apply tw-sticky tw-top-0 tw-z-10;
        }
      }

      // sticky table headers for vertical scroll
      thead {
        @apply tw-sticky tw-top-0 tw-z-10;
      }
    }

    // right and left gradients for horizontal scroll
    .gl-right-gradient {
      &::after {
        content: '';
        background-image: linear-gradient(
          to left,
          mat.m2-get-color-from-palette(gl.$surface, 400),
          rgb(255 255 255 / 0%)
        );
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 100%;
      }
    }

    .gl-left-gradient {
      &::before {
        content: '';
        background-image: linear-gradient(
          to right,
          mat.m2-get-color-from-palette(gl.$surface, 400),
          rgb(255 255 255 / 0%)
        );
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 100%;
      }
    }

    .gl-horizontal-scroll-container {
      max-width: 1280px;
      position: relative;

      .gl-horizontal-inner-scroll {
        overflow-x: auto;
      }

      .gl-sticky-column {
        tr > th:first-child,
        tr > td:first-child {
          position: sticky;
          left: 0;
          z-index: 1;
          overflow: unset;
        }

        th:first-child::after,
        td:first-child::after {
          content: '';
          background-image: linear-gradient(to right, rgb(0 0 0 / 20%), rgb(255 255 255 / 0%));
          position: absolute;
          right: -15px;
          top: 0;
          width: 15px;
          height: 100%;
        }
      }
    }
  }
}
