@use '../utilities' as util;

@mixin theme($theme) {
  .gl-minimap-container {
    @apply tw-pointer-events-none tw-sticky tw-top-4 tw-z-200 tw-m-0 tw-flex tw-items-end tw-overflow-visible tw-p-0;
  }

  .gl-minimap {
    @apply tw-pointer-events-auto tw-relative tw-inline-flex tw-bg-surface-300 tw-p-1.5;
    @include util.elevation-raised;
  }

  .gl-minimap-selector-box {
    @apply tw-absolute tw-left-0 tw-top-0 tw-h-full tw-cursor-ew-resize tw-outline tw-outline-4;

    outline-color: rgb(var(--ui-primary));
    background-color: rgb(var(--ui-primary) / 30%);
  }

  .gl-hscroll-container {
    @apply tw-m-4 tw-overflow-auto tw-whitespace-nowrap;
  }
}
