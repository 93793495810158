@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);

  .gl-critical-bar {
    .mdc-linear-progress__buffer-bar {
      background-color: gl.$critical;
      opacity: 0.25;
    }

    .mdc-linear-progress__bar-inner {
      border-color: gl.$critical;
    }
  }

  .gl-primary-bar {
    .mdc-linear-progress__buffer-bar {
      background-color: mat.m2-get-color-from-palette($primary);
      opacity: 0.25;
    }

    .mdc-linear-progress__bar-inner {
      border-color: mat.m2-get-color-from-palette($primary);
    }
  }
}
