@mixin elevation-inset {
  @apply tw-bg-surface-inset;
}

@mixin elevation-flat {
  @apply tw-bg-surface-flat;

  box-shadow: none;
}

@mixin elevation-raised {
  @apply tw-bg-surface-raised;

  box-shadow: 0 var(--spacing-1-2) var(--spacing-2) 0 rgb(0 0 0 / var(--shadow-raised-opacity));
}

@mixin elevation-raised-hover {
  transition: box-shadow ease-in 0.3s;

  &:hover {
    @apply tw-bg-surface-hover;

    box-shadow: 0 var(--spacing-2) var(--spacing-6) 0 rgb(0 0 0 / var(--shadow-raised-hover-opacity));
  }
}

@mixin elevation-popover {
  @apply tw-bg-surface-popover;

  box-shadow: 0 var(--spacing-1) var(--spacing-4) 0 rgb(0 0 0 / var(--shadow-popover-opacity));
}

@mixin elevation-overlay {
  @apply tw-bg-surface-overlay;

  box-shadow: 0 var(--spacing-2) var(--spacing-6) 0 rgb(0 0 0 / var(--shadow-overlay-opacity));
}
