@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  gl-counter {
    // no suffix
    .gl-counter-input {
      @apply tw-text-center;
    }

    // suffix
    .gl-counter-input-suffix {
      @apply tw-w-1/2 tw-text-right;
    }

    .gl-counter-suffix {
      @apply tw-relative tw-left-2;
    }

    // make hints block elements
    .mat-mdc-form-field-hint-wrapper {
      @apply tw-block;

      .mat-mdc-form-field-hint {
        @apply tw-block;
      }
    }

    input[type='number'] {
      appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        @apply tw-m-0 tw-appearance-none;
      }
    }
  }

  [data-theme='desktop-dark']gl-counter {
    .mat-mdc-form-field-flex {
      height: 2.75rem;
    }
  }

  [data-theme='kiosk-light'] gl-counter,
  [data-theme='kiosk-dark'] gl-counter {
    .gl-read-only {
      .mat-mdc-form-field.mat-form-field-appearance-outline {
        .mdc-text-field--outlined,
        .mdc-text-field--outlined.mdc-text-field--disabled {
          .mdc-text-field__input {
            @apply tw-text-primary;
          }

          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            @apply tw-border-none;
          }
        }
      }
    }

    .mat-mdc-form-field.mat-form-field-appearance-outline {
      @apply tw-w-36;

      .mdc-text-field__input {
        font-size: var(--heading-1-font-size);
        line-height: var(--spacing-11); // input height
      }

      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-infix {
          padding: 0;
        }
      }

      .mdc-text-field--outlined.mdc-text-field--disabled {
        .mdc-text-field__input {
          @apply tw-text-disabled;
        }

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          @apply tw-border-default;
        }
      }
    }

    gl-icon-button {
      @apply tw-text-ui-primary;

      .mat-mdc-button-persistent-ripple {
        @apply tw-border-2 tw-border-default;
      }

      .mat-mdc-icon-button:disabled {
        @apply tw-text-disabled;
      }

      .mat-mdc-icon-button.mat-mdc-button-base {
        --mat-icon-button-ripple-color: rgb(var(--decor-primary) / 12%);
        --mat-mdc-button-persistent-ripple-color: rgb(var(--decor-primary) / 12%);
        --mat-icon-button-state-layer-color: rgb(var(--decor-primary) / 12%);
      }
    }
  }
}
