/* stylelint-disable */
$dv-cat1: #389cd8;
$dv-cat2: #37c471;
$dv-cat3: #f8f55b;
$dv-cat4: #a446a8;
$dv-cat5: #ff9703;
$dv-cat6: #ff5d5d;
$dv-cat7: #00c3c9;
$dv-cat8: #cc338c;
$dv-cat9: #ffc827;
$dv-cat10: #a9ab00;
$dv-cat11: #c6c6c6;
$dv-cat12: #79d58a;
$seq-primary-start: #2e5d8e;
$seq-primary-mid: #00b593;
$seq-primary-end: #eeec65;
$seq-alt1-start: #e5fff0;
$seq-alt1-mid: #6fb3b4;
$seq-alt1-end: #0b6390;
$seq-alt2-start: #fad85f;
$seq-alt2-mid: #d77269;
$seq-alt2-end: #85086d;
$div-primary-start: #f28a2d;
$div-primary-mid: #ffd;
$div-primary-end: #107bbc;
$div-alt1-start: #c7246d;
$div-alt1-mid: #f1f1f1;
$div-alt1-end: #0174cf;
$div-alt2-start: #37c471;
$div-alt2-mid: #ffd;
$div-alt2-end: #ff5d5d;
/* stylelint-enable */
