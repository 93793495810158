/* stylelint-disable */
@use '@angular/material' as mat;

$apricot: mat.m2-define-palette(
  (
    50: #fffaf0,
    100: #fef2d9,
    200: #feeac0,
    300: #fde2a7,
    400: #fcdb94,
    500: #fcd581,
    600: #fcd079,
    700: #fbca6e,
    800: #fbc464,
    900: #faba51,
    A100: #ffffff,
    A200: #ffffff,
    A400: #fffaf1,
    A700: #fff0d8,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #000000,
      500: #000000,
      600: #000000,
      700: #000000,
      800: #000000,
      900: #000000,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  ),
  500,
  300,
  700
);

$azure: mat.m2-define-palette(
  (
    50: #e7f3fd,
    100: #c2e1fb,
    200: #99cef9,
    300: #70baf6,
    400: #52abf4,
    500: #339cf2,
    600: #2e8ee4,
    700: #277cd1,
    800: #216bbf,
    900: #184da0,
    A100: #ffffff,
    A200: #e5efff,
    A400: #b2d0ff,
    A700: #98c0ff,
    contrast: (
      50: rgba(black, 0.87),
      100: rgba(black, 0.87),
      200: rgba(black, 0.87),
      300: rgba(black, 0.87),
      400: rgba(black, 0.87),
      500: rgba(black, 0.87),
      600: white,
      700: white,
      800: white,
      900: white,
      A100: rgba(black, 0.87),
      A200: rgba(black, 0.87),
      A400: rgba(black, 0.87),
      A700: rgba(black, 0.87),
    ),
  ),
  500,
  300,
  700
);

$neutral: mat.m2-define-palette(
  (
    50: #ebebec,
    100: #cdcdd1,
    200: #abadb1,
    300: #898c91,
    400: #70737a,
    500: #575a62,
    600: #4f525a,
    700: #464850,
    800: #393b43,
    900: #2c2e34,
    contrast: (
      50: rgba(black, 0.87),
      100: rgba(black, 0.87),
      200: rgba(black, 0.87),
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
    ),
  ),
  500,
  300,
  800
);

$scarlet: mat.m2-define-palette(
  (
    50: #ffe9ed,
    100: #fec8d1,
    200: #fda4b2,
    300: #fc7f93,
    400: #fc637c,
    500: #fb4865,
    600: #fa415d,
    700: #fa3853,
    800: #f93049,
    900: #f82137,
    A100: #ffffff,
    A200: #fffcfd,
    A400: #ffc9ce,
    A700: #ffb0b7,
    contrast: (
      50: rgba(black, 0.87),
      100: rgba(black, 0.87),
      200: rgba(black, 0.87),
      300: rgba(black, 0.87),
      400: rgba(black, 0.87),
      500: rgba(black, 0.87),
      600: white,
      700: white,
      800: white,
      900: white,
      A100: rgba(black, 0.87),
      A200: rgba(black, 0.87),
      A400: rgba(black, 0.87),
      A700: rgba(black, 0.87),
    ),
  ),
  300,
  200,
  400
);

$seafoam: mat.m2-define-palette(
  (
    50: #e9f6eb,
    100: #c8e9cc,
    200: #a3daaa,
    300: #7ecb88,
    400: #62c06f,
    500: #46b555,
    600: #3da64b,
    700: #329340,
    800: #288236,
    900: #136323,
    A100: #c9ffce,
    A200: #96ff9f,
    A400: #63ff70,
    A700: #4aff59,
    contrast: (
      50: rgba(black, 0.87),
      100: rgba(black, 0.87),
      200: rgba(black, 0.87),
      300: rgba(black, 0.87),
      400: rgba(black, 0.87),
      500: rgba(black, 0.87),
      600: rgba(black, 0.87),
      700: white,
      800: white,
      900: white,
      A100: rgba(black, 0.87),
      A200: rgba(black, 0.87),
      A400: rgba(black, 0.87),
      A700: rgba(black, 0.87),
    ),
  ),
  500,
  300,
  800
);

$surface: mat.m2-define-palette(
  (
    50: #748097,
    100: #5d6b85,
    200: #505d75,
    300: #3a4457,
    400: #2f3849,
    500: #283040,
    600: #242b3a,
    700: #1e2532,
    800: #181e2a,
    900: #0f131c,
    contrast: (
      50: #909092,
      100: white,
      200: white,
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
    ),
  ),
  700,
  500,
  900
);

/* stylelint-enable */
