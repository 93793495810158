@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;

@mixin theme($theme) {
  .mat-tree {
    @apply tw-relative tw-bg-transparent;

    .oc-tree-collapse {
      @apply tw-hidden;
    }

    .mat-nested-tree-node div[role='group'] {
      padding-left: 1.5rem;
    }

    .mat-tree-node {
      position: relative;

      &.oc-selected::before,
      &.oc-warning::before {
        @include util.status-border;
      }

      &.oc-selected::before {
        background-color: mat.m2-get-color-from-palette(gl.$seafoam);
      }

      &.oc-warning::before {
        background-color: gl.$warning;
      }
    }

    div[role='group'] > .mat-tree-node {
      padding-left: 2.5rem;
    }
  }
}
