/* stylelint-disable */
@use '@angular/material' as mat;
@use 'sass:map';
@use 'palette';
@use 'typography';

// Background palette for light themes.
$light-theme-background-palette: (
  status-bar: map.get(mat.$m2-grey-palette, 300),
  app-bar: map.get(mat.$m2-grey-palette, 100),
  background: map.get(mat.$m2-grey-palette, 50),
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: rgba(black, 0.12),
  selected-button: map.get(mat.$m2-grey-palette, 300),
  selected-disabled-button: map.get(mat.$m2-grey-palette, 400),
  disabled-button-toggle: map.get(mat.$m2-grey-palette, 200),
  unselected-chip: map.get(mat.$m2-grey-palette, 300),
  disabled-list-option: map.get(mat.$m2-grey-palette, 200),
  tooltip: map.get(mat.$m2-grey-palette, 700),
);

// Foreground palette for light themes.
$light-theme-foreground-palette: (
  base: black,
  divider: rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.26),
  disabled-text: rgba(black, 0.38),
  elevation: black,
  hint-text: rgba(black, 0.38),
  secondary-text: rgba(black, 0.54),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgb(var(--neutral-alt-900)),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

$light-palette: mat.m2-define-light-theme(
  (
    color: (
      primary: palette.$azure,
      accent: palette.$azure,
      warn: palette.$scarlet,
    ),
    typography: typography.$typography,
    density: 0,
  )
);

$light-palette-bg: map.merge($light-palette, 'color', 'background', $light-theme-background-palette);
$light-theme: map.merge($light-palette-bg, 'color', 'foreground', $light-theme-foreground-palette);
/* stylelint-enable */
