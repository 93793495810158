@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  .cdk-overlay-dark-backdrop {
    background-color: rgb(0 0 0 / 60%);
  }

  .cdk-overlay-pane {
    /* Position caret on top */
    &.gl-top .gl-popover-caret {
      @apply tw-border-t-surface-300;

      border-width: 10px 10px 0;
      bottom: -10px;
      transform: translateX(-50%);
    }

    /* Position caret on bottom */
    &.gl-bottom .gl-popover-caret {
      @apply tw-border-b-surface-300;

      border-width: 0 10px 10px;
      top: -10px;
    }

    &.gl-left .gl-popover-caret {
      left: 10%;
    }

    &.gl-right .gl-popover-caret {
      left: 90%;
    }

    &.gl-center {
      left: 50%;
    }
  }
}
