@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  mat-stepper.mat-stepper-vertical,
  mat-stepper.mat-stepper-horizontal {
    background: none;
  }

  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-number {
    color: var(--mdc-theme-azure, mat.m2-get-color-from-palette(gl.$azure, A100));
  }
}
