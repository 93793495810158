@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities/' as util;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $is-dark-theme: map.get($theme, is-dark);

  gl-dialog {
    @apply tw-flex tw-h-full tw-flex-col;
  }

  .mdc-dialog.mat-mdc-dialog-container {
    .gl-dialog-caption {
      @apply tw-mb-4 tw-text-secondary;
    }

    .mat-mdc-dialog-title {
      --mat-dialog-headline-padding: var(--spacing-6) var(--spacing-6) 0px;

      .gl-dialog-title {
        @apply tw-flex tw-items-center tw-pb-4;
      }

      .gl-dialog-subtitle {
        @apply oc-text-heading-4 tw-pb-4 tw-text-secondary;
      }

      &::before {
        @apply tw-hidden;
      }
    }

    &.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
      --mat-dialog-with-actions-content-padding: 0px var(--spacing-6) var(--spacing-8);
    }

    .mat-mdc-dialog-content {
      --mat-dialog-content-padding: 0px var(--spacing-6) var(--spacing-6);

      // mdc theme sets supporting text to black, override to white
      color: inherit;
    }

    .mat-mdc-dialog-actions {
      --mat-dialog-actions-padding: 0px var(--spacing-6) var(--spacing-6);
    }
  }

  [data-theme='desktop-dark'] .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 0.25rem;

    .mat-mdc-dialog-surface {
      @include util.elevation-overlay;
    }

    .mat-mdc-dialog-actions {
      @apply tw-mt-auto tw-justify-end tw-gap-x-4;
    }
  }

  [data-theme='kiosk-light'] .mat-mdc-dialog-container,
  [data-theme='kiosk-dark'] .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 1.5rem;

    .mat-mdc-dialog-surface {
      @apply tw-bg-surface-overlay;
    }
  }
}
