@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.m2-get-color-config($theme);
  $foreground: map.get($config, foreground);

  .gl-block {
    .oc-tag {
      @apply tw-mx-0;
    }
  }

  // Dividers added to context or mixed blocks used in summary component
  .gl-summary-dividers > *:not(:last-child) {
    @apply tw-border-r tw-border-solid tw-pr-4;
    @include util.color-border;
  }
}
