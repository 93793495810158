@use 'sass:map';
@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;

@mixin theme($theme) {
  .oc-widget {
    .mat-mdc-card-header {
      @apply tw-pr-2.5 tw-text-base tw-font-bold;
    }

    .oc-widget-menu {
      @apply tw-absolute tw-right-0 tw-top-0;
    }
  }

  .gl-widget-shell {
    @apply tw-rounded tw-p-4 tw-text-start;
    @include util.elevation-flat;
  }

  .gl-widget-shell-square::before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: 100%;
    width: 0;
  }

  .gl-widget-shell-square {
    display: flex;
  }

  .gl-widget-shell-kpi {
    @apply tw-col-span-1 tw-min-h-40 tw-cursor-pointer tw-rounded tw-p-4 tw-text-start md:tw-h-52 lg:tw-h-44;

    border: 2px solid mat.m2-get-color-from-palette(gl.$surface, 400);
    @include util.elevation-raised;
    @include util.elevation-raised-hover;

    &:hover {
      border: 2px solid mat.m2-get-color-from-palette(gl.$seafoam, 500);
    }
  }

  .gl-widget-shell-shortcut {
    @apply tw-cursor-pointer tw-rounded;
    @include util.elevation-raised;
    @include util.elevation-raised-hover;

    &:hover {
      @apply tw-outline tw-outline-2 tw-outline-seafoam-500;
    }

    .gl-widget-shell-shortcut-text {
      @apply tw-rounded-b;
      @include util.elevation-inset;
    }

    .mat-icon {
      font-size: 40px;
      line-height: 40px;
      @apply tw-h-10 tw-w-10;
    }

    // custom override for gl_control_check widget shortcut
    .gl-control-check {
      .mat-icon {
        @apply tw-w-32;

        height: 35px;
        line-height: 35px;
      }
    }
  }
}
