@use '@angular/material' as mat;
@mixin theme($theme) {
  gl-chrome {
    @apply tw-z-100 tw-block tw-w-full;

    .mat-toolbar-row,
    .mat-toolbar-single-row {
      @apply tw-px-6;

      --mat-toolbar-standard-height: 96px;
      --mat-toolbar-container-background-color: rgb(var(--bg-surface-default));
      --mat-toolbar-container-text-color: rgb(var(--text-primary));
      --mat-toolbar-title-text-weight: 400;

      .mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
        padding: 10px;

        .mat-icon {
          @apply tw-h-8 tw-w-8 tw-text-primary;

          font-size: 3rem;
        }
      }

      .gl-chrome-divider {
        @apply tw-ml-6 tw-mr-3 tw-border-l-2 tw-border-solid;

        height: 3.75rem;
        border-image: linear-gradient(
          to bottom,
          rgb(var(--neutral-alt-100) / 30%) 0%,
          rgb(var(--green-500) / 30%) 50%,
          rgb(var(--neutral-alt-100) / 30%) 100%
        );
        border-image-slice: 1;
      }

      .gl-chrome-actions {
        @apply tw-flex tw-gap-4;

        // Matching the space of the logo and home button to center the text without using position absolute
        margin-left: 157px;
      }
    }
  }
}
