@mixin theme($theme) {
  .gl-vertical {
    @apply tw-flex tw-flex-col tw-items-start;

    .mat-mdc-radio-button {
      @apply tw-flex;
    }
  }

  [data-theme='kiosk-light'] gl-radio-button,
  [data-theme='kiosk-dark'] gl-radio-button {
    --mdc-radio-state-layer-size: var(--spacing-8);

    .mat-mdc-radio-button.mat-primary {
      --mdc-radio-disabled-selected-icon-color: rgb(var(--text-disabled));
      --mdc-radio-disabled-unselected-icon-color: rgb(var(--ui-disabled));
      --mdc-radio-unselected-hover-icon-color: rgb(var(--ui-neutral));
      --mdc-radio-unselected-icon-color: rgb(var(--ui-neutral));
      --mdc-radio-unselected-pressed-icon-color: rgb(var(--ui-neutral));
      --mdc-radio-selected-focus-icon-color: rgb(var(--ui-secondary));
      --mdc-radio-selected-hover-icon-color: rgb(var(--ui-secondary));
      --mdc-radio-selected-icon-color: rgb(var(--ui-secondary));
      --mdc-radio-selected-pressed-icon-color: rgb(var(--ui-secondary));
      --mat-radio-ripple-color: rgb(var(--ui-neutral));
      --mat-radio-checked-ripple-color: rgb(var(--ui-secondary));
      --mat-radio-disabled-label-color: rgb(var(--text-disabled));
    }

    .mat-mdc-radio-button .mdc-radio {
      @apply tw-h-6 tw-w-6 tw-p-1.5;
    }

    .mdc-radio__outer-circle {
      @apply tw-h-6 tw-w-6;
    }

    .mdc-radio__background {
      @apply tw-h-6 tw-w-6;
    }

    .mdc-radio__inner-circle {
      @apply tw-bg-ui-secondary;

      top: calc(var(--spacing-4) / -4);
      left: calc(var(--spacing-4) / -4);
      border-width: var(--spacing-4);
    }

    .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
      @apply tw-hidden;
    }

    .mdc-label {
      @apply oc-text-body tw-text-primary;
    }
  }
}
