@use 'sass:list';
@use 'sass:math';

/* stylelint-disable */

@tailwind base;
@tailwind components;
@tailwind utilities;

$font-size-scaler: 1.2;
// Determines how much larger each level in the type scale is, in comparison to its predecessor
// eg. If heading-3 is 3rem, and $font-size-scaler is 1.2, heading-2 will be 3rem * 1.2 = 3.6rem

$line-height-scale: 1.5;
// Determines how large line height is, in relation to text size
// eg. If body text size is 1rem, and $line-height-scale is 1.5, body will have a line height of 1rem * 1.5 = 1.5rem

$incremental-font-styles:
  // styles that are EQUAL TO or LARGER than the base font-size
  // order smallest --> largest
  body, heading-4, heading-3, heading-2, heading-1, headline-4, headline-3, headline-2, headline-1;

$decremental-font-styles:
  // styles that are SMALLER than the base font-size
  // order largest --> smallest
  caption, caption-sm;

$spacing-sizes:
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  16,
  19 /* extend */,
  20,
  24,
  28,
  32,
  36,
  40,
  44,
  48,
  52,
  56,
  60,
  64,
  72,
  76 /* extend */,
  80,
  88,
  96,
  125,
  250;

@layer base {
  :root {
    --base-font-size: 16; // this may be changed by theme or @media query. ALL spacing and font sizes are scaled in relation to this value.
    --spacing-unit: calc(var(--base-font-size) / 4);
    --line-height-unit: calc(var(--base-font-size) / 4);

    @each $style in $incremental-font-styles {
      $index: list.index($incremental-font-styles, $style);
      $multiplier: math.pow($font-size-scaler, $index - 1);
      $line-height-multiplier: $multiplier * $line-height-scale;

      @if $index == 1 {
        // creates var for body text size, sized in rems
        --#{$style}-font-size: calc(var(--base-font-size) / 16 * 1rem);
      } @else {
        // creates vars for text sizes LARGER than body, sized in rems
        --#{$style}-font-size: calc(var(--base-font-size) * #{$multiplier} / 16 * 1rem);
      }
      --#{$style}-line-height: calc(
        (#{round }(calc(var(--base-font-size) * #{$line-height-multiplier}), var(--line-height-unit))) / 16 * 1rem
      );
      // creates line-height vars for text sizes body and larger
      // line height = $font-size * $line-height-scale, rounded to the nearest --spacing-unit and converted to rems
    }
    @each $style in $decremental-font-styles {
      $index: list.index($decremental-font-styles, $style);
      $multiplier: math.pow($font-size-scaler, $index * -1);
      $line-height-multiplier: $multiplier * $line-height-scale;

      // do the same thing for sizes SMALLER than body
      --#{$style}-font-size: calc(var(--base-font-size) * #{$multiplier} / 16 * 1rem);
      --#{$style}-line-height: calc(
        (#{round }(calc(var(--base-font-size) * #{$line-height-multiplier}), var(--line-height-unit))) / 16 * 1rem
      );
    }
    @each $size in $spacing-sizes {
      // create sizing vars
      --spacing-#{$size}: calc(#{$size} * var(--spacing-unit) / 16 * 1rem);
    }
    // half sizes need special names (decimals are not valid is CSS var names)
    #{--spacing-1-2}: calc(0.5 * var(--spacing-unit) / 16 * 1rem);
    #{--spacing-1-1-2}: calc(1.5 * var(--spacing-unit) / 16 * 1rem);
    #{--spacing-2-1-2}: calc(2.5 * var(--spacing-unit) / 16 * 1rem);
  }

  [data-theme='desktop-dark'] {
    --base-font-size: 16;

    --green-100: 126 203 136;
    --green-400: 52 179 79;
    --green-600: 126 203 136;
    --yellow-100: 252 213 129;
    --yellow-600: 253 226 157;
    --red-100: 252 127 147;
    --red-400: 255 111 114;
    --red-600: 252 127 147;
    --neutral-100: 205 205 209;
    --neutral-300: 137 140 145;
    --neutral-alt-100: 30 37 50; // surface 700
    --neutral-alt-900: 255 255 255; // text primary
    --blue-500: 51 156 242;
    --apricot-900: 250 186 81;

    --text-primary: var(--neutral-alt-900);
    --text-secondary: var(--neutral-100);
    --text-success: var(--green-600);
    --text-warning: var(--yellow-600);
    --text-warning-strong: var(--apricot-900);
    --text-critical: var(--red-600);
    --text-link: 112 186 246;
    --bg-success: var(--green-100);
    --bg-warning: var(--yellow-100);
    --bg-critical: var(--red-100);
    --bg-neutral: var(--neutral-alt-100);
    --border-critical: var(--red-600);
    --bg-page: var(--neutral-alt-100);
    --ui-primary: var(--blue-500);
    --bg-warning-strong: var(--apricot-900);
    --text-on-warning-strong: var(--neutral-alt-100);

    --decor-secondary: var(--green-400);
    --decor-critical: var(--red-400);

    // elevations
    --bg-surface-default: 47 56 73; // surface 400
    --bg-surface-flat: var(--bg-surface-default);
    --bg-surface-raised: var(--bg-surface-default);
    --bg-surface-overlay: var(--bg-surface-default);
    --bg-surface-popover: 58 68 87; // surface 300
    --shadow-raised-opacity: 0.5;
    --shadow-raised-hover-opacity: 0.5;
    --shadow-popover-opacity: 0.3;
    --shadow-overlay-opacity: 0.5;
  }

  [data-theme='kiosk-light'] {
    --blue-33: 255 255 255;
    --blue-67: 235 250 253;
    --blue-100: 205 243 251;
    --blue-200: 120 223 244;
    --blue-300: 31 199 235;
    --blue-400: 10 169 223;
    --blue-500: 3 142 206;
    --blue-600: 1 110 186;
    --blue-700: 17 70 154;
    --blue-800: 15 46 108;
    --blue-900: 8 24 57;

    --green-33: 255 255 255;
    --green-67: 237 252 240;
    --green-100: 201 246 209;
    --green-200: 118 231 139;
    --green-300: 85 206 109;
    --green-400: 52 179 79;
    --green-500: 21 154 50;
    --green-600: 5 125 29;
    --green-700: 2 87 13;
    --green-800: 1 59 3;
    --green-900: 0 32 0;

    --neutral-alt-33: 255 255 255;
    --neutral-alt-67: 246 247 246;
    --neutral-alt-100: 233 236 233;
    --neutral-alt-200: 203 210 203;
    --neutral-alt-300: 176 186 176;
    --neutral-alt-400: 147 161 147;
    --neutral-alt-500: 122 138 122;
    --neutral-alt-600: 92 112 92;
    --neutral-alt-700: 55 81 55;
    --neutral-alt-800: 28 56 28;
    --neutral-alt-900: 0 32 0;

    --neutral-33: 255 255 255;
    --neutral-67: 247 247 247;
    --neutral-100: 235 235 235;
    --neutral-200: 209 209 209;
    --neutral-300: 183 183 183;
    --neutral-400: 157 157 157;
    --neutral-500: 133 133 133;
    --neutral-600: 107 107 107;
    --neutral-700: 74 74 74;
    --neutral-800: 50 50 50;
    --neutral-900: 26 26 26;

    --purple-33: 255 255 255;
    --purple-67: 250 246 252;
    --purple-100: 242 233 247;
    --purple-200: 224 201 234;
    --purple-300: 206 171 223;
    --purple-400: 187 139 210;
    --purple-500: 170 110 199;
    --purple-600: 144 79 180;
    --purple-700: 107 40 151;
    --purple-800: 73 18 118;
    --purple-900: 39 9 66;

    --red-33: 255 255 255;
    --red-67: 255 245 246;
    --red-100: 255 229 230;
    --red-200: 255 194 195;
    --red-300: 255 156 158;
    --red-400: 255 111 114;
    --red-500: 255 50 53;
    --red-600: 204 44 27;
    --red-700: 150 15 7;
    --red-800: 103 10 5;
    --red-900: 57 6 3;

    --transparency-wip-33: rgba(0 0 0 / 0.05);
    --transparency-wip-67: 0 0 0;
    --transparency-wip-100: rgba(0 0 0 / 0.1);
    --transparency-wip-200: 224 201 234;
    --transparency-wip-300: 206 171 223;
    --transparency-wip-400: 187 139 210;
    --transparency-wip-500: 170 110 199;
    --transparency-wip-600: 144 79 180;
    --transparency-wip-700: 107 40 151;
    --transparency-wip-800: 73 18 118;
    --transparency-wip-900: 39 9 66;

    --yellow-33: 255 255 255;
    --yellow-67: 255 247 226;
    --yellow-100: 255 235 183;
    --yellow-200: 255 200 62;
    --yellow-300: 255 163 21;
    --yellow-400: 248 121 4;
    --yellow-500: 211 104 3;
    --yellow-600: 170 83 3;
    --yellow-700: 118 58 2;
    --yellow-800: 80 39 1;
    --yellow-900: 42 21 1;

    // can be used for background or text
    --decor-primary: var(--blue-300);
    --decor-secondary: var(--green-400);
    --decor-neutral: var(--neutral-alt-300);
    --decor-success: var(--green-400);
    --decor-warning: var(--yellow-400);
    --decor-critical: var(--red-400);

    --bg-surface-inset-opacity: 0.04;
    --shadow-raised-opacity: 0.15;
    --shadow-raised-hover-opacity: 0.15;
    --shadow-popover-opacity: 0.1;
    --shadow-overlay-opacity: 0.15;
  }

  [data-theme='kiosk-dark'] {
    --blue-33: 0 27 53;
    --blue-67: 0 34 67;
    --blue-100: 0 47 92;
    --blue-200: 0 73 136;
    --blue-300: 1 99 168;
    --blue-400: 1 127 202;
    --blue-500: 5 159 218;
    --blue-600: 32 201 236;
    --blue-700: 130 225 245;
    --blue-800: 201 242 251;
    --blue-900: 255 255 255;

    --green-33: 0 32 0;
    --green-67: 0 40 1;
    --green-100: 1 55 2;
    --green-200: 2 85 12;
    --green-300: 4 113 24;
    --green-400: 6 142 37;
    --green-500: 41 171 69;
    --green-600: 88 207 111;
    --green-700: 124 234 144;
    --green-800: 201 246 209;
    --green-900: 255 255 255;

    --neutral-alt-33: 0 32 0;
    --neutral-alt-67: 6 40 10;
    --neutral-alt-100: 18 53 28;
    --neutral-alt-200: 49 79 58;
    --neutral-alt-300: 76 103 84;
    --neutral-alt-400: 105 127 112;
    --neutral-alt-500: 136 153 141;
    --neutral-alt-600: 176 187 179;
    --neutral-alt-700: 206 213 208;
    --neutral-alt-800: 232 235 233;
    --neutral-alt-900: 255 255 255;

    --neutral-33: 23 23 23;
    --neutral-67: 50 50 50;
    --neutral-100: 57 57 57;
    --neutral-200: 74 74 74;
    --neutral-300: 91 91 91;
    --neutral-400: 111 111 111;
    --neutral-500: 132 132 132;
    --neutral-600: 161 161 161;
    --neutral-700: 207 207 207;
    --neutral-800: 248 248 248;
    --neutral-900: 255 255 255;

    --purple-33: 42 0 78;
    --purple-67: 51 0 96;
    --purple-100: 70 0 125;
    --purple-200: 113 0 174;
    --purple-300: 149 35 203;
    --purple-400: 171 82 214;
    --purple-500: 190 121 223;
    --purple-600: 213 168 235;
    --purple-700: 229 201 242;
    --purple-800: 243 230 249;
    --purple-900: 255 255 255;

    --red-33: 62 0 1;
    --red-67: 76 0 1;
    --red-100: 100 0 2;
    --red-200: 151 0 3;
    --red-300: 197 0 18;
    --red-400: 245 0 27;
    --red-500: 255 91 110;
    --red-600: 255 156 168;
    --red-700: 255 196 203;
    --red-800: 255 228 232;
    --red-900: 255 255 255;

    --transparency-wip-33: rgba(255 255 255 / 0.05);
    --transparency-wip-67: 73 18 119;
    --transparency-wip-100: rgba(255 255 255 / 0.1);
    --transparency-wip-200: 106 39 150;
    --transparency-wip-300: 126 60 166;
    --transparency-wip-400: 148 83 183;
    --transparency-wip-500: 169 107 198;
    --transparency-wip-600: 190 144 212;
    --transparency-wip-700: 223 199 234;
    --transparency-wip-800: 250 247 252;
    --transparency-wip-900: 255 255 255;

    --yellow-33: 43 21 0;
    --yellow-67: 55 27 0;
    --yellow-100: 75 36 0;
    --yellow-200: 117 56 0;
    --yellow-300: 154 74 0;
    --yellow-400: 195 94 0;
    --yellow-500: 236 114 0;
    --yellow-600: 255 164 10;
    --yellow-700: 255 204 75;
    --yellow-800: 255 233 178;
    --yellow-900: 255 255 255;
    // can be used for background or text
    --decor-primary: var(--blue-500);
    --decor-secondary: var(--green-500);
    --decor-neutral: var(--neutral-alt-400);
    --decor-success: var(--green-500);
    --decor-warning: var(--yellow-500);
    --decor-critical: var(--red-500);

    --bg-surface-inset-opacity: 0.08;
    --shadow-raised-opacity: 0.5;
    --shadow-raised-hover-opacity: 0.5;
    --shadow-popover-opacity: 0.3;
    --shadow-overlay-opacity: 0.5;
  }

  [data-theme='kiosk-light'],
  [data-theme='kiosk-dark'] {
    --base-font-size: 24;
    // abstracted theme variables
    // can be used for bg or text
    --ui-primary: var(--blue-500);
    --ui-secondary: var(--green-500);
    --ui-neutral: var(--neutral-alt-500);
    --ui-success: var(--green-500);
    --ui-warning: var(--yellow-500);
    --ui-critical: var(--red-500);
    --ui-disabled: var(--neutral-alt-300);
    // background
    --bg-primary: var(--blue-600);
    --bg-secondary: var(--green-600);
    --bg-page: var(--neutral-alt-100);
    --bg-surface-default: var(--neutral-alt-33);
    --bg-surface-flat: var(--neutral-alt-33);
    --bg-surface-raised: var(--neutral-alt-33);
    --bg-surface-overlay: var(--neutral-alt-33);
    --bg-surface-popover: var(--neutral-alt-33);
    --bg-surface-inset: 0 0 0;
    --bg-surface-hover: var(--green-67);
    --bg-neutral: var(--neutral-alt-100);
    --bg-info: var(--blue-100);
    --bg-critical: var(--red-100);
    --bg-warning: var(--yellow-100);
    --bg-success: var(--green-100);
    --bg-neutral-strong: var(--neutral-alt-600);
    --bg-info-strong: var(--blue-600);
    --bg-critical-strong: var(--red-600);
    --bg-warning-strong: var(--yellow-600);
    --bg-success-strong: var(--green-600);
    // text
    --text-primary: var(--neutral-alt-900);
    --text-secondary: var(--neutral-alt-600);
    --text-disabled: var(--neutral-alt-300);
    --text-link: var(--blue-600);
    --text-link-hover: var(--blue-800);
    --text-brand-primary: var(--blue-600);
    --text-brand-secondary: var(--green-600);
    --text-success: var(--green-600);
    --text-warning: var(--yellow-600);
    --text-critical: var(--red-600);
    --text-on-primary: var(--blue-33);
    --text-on-secondary: var(--green-33);
    --text-on-neutral: var(--neutral-alt-900);
    --text-on-info: var(--blue-900);
    --text-on-critical: var(--red-900);
    --text-on-warning: var(--yellow-900);
    --text-on-success: var(--green-900);
    --text-on-neutral-strong: var(--neutral-alt-33);
    --text-on-info-strong: var(--blue-33);
    --text-on-critical-strong: var(--red-33);
    --text-on-warning-strong: var(--yellow-33);
    --text-on-success-strong: var(--green-33);
    // borders
    --border-default: var(--neutral-alt-300);
    --border-strong: var(--neutral-alt-500);
    --border-contrast: var(--neutral-alt-900);
    --border-input: var(--neutral-alt-500);
    --border-critical: var(--red-600);
    --border-focus: var(--blue-600);
  }

  button:focus {
    outline: 0;
  }
}

@layer components {
  .mat-typography {
    h1 {
      font-size: var(--heading-1-font-size) !important;
      line-height: var(--heading-1-line-height) !important;
      font-weight: 500 !important;
    }
    h2 {
      font-size: var(--heading-2-font-size) !important;
      line-height: var(--heading-2-line-height) !important;
      font-weight: 500 !important;
    }
    h3 {
      font-size: var(--heading-3-font-size) !important;
      line-height: var(--heading-3-line-height) !important;
      font-weight: 500 !important;
    }
    h4 {
      font-size: var(--heading-4-font-size) !important;
      line-height: var(--heading-4-line-height) !important;
      font-weight: 500 !important;
    }
  }
  .oc-text-headline-1 {
    @apply tw-text-15xl tw-font-medium;
  }
  .oc-text-headline-2 {
    @apply tw-text-11xl tw-font-medium;
  }
  .oc-text-headline-3 {
    @apply tw-text-9xl tw-font-medium;
  }
  .oc-text-headline-4 {
    @apply tw-text-6xl tw-font-medium;
  }
  .oc-text-heading-1 {
    @apply tw-text-5xl tw-font-medium;
  }
  .oc-text-heading-2 {
    @apply tw-text-4xl tw-font-medium;
  }
  .oc-text-heading-3 {
    @apply tw-text-2xl tw-font-medium;
  }
  .oc-text-heading-4 {
    @apply tw-text-xl tw-font-medium;
  }
  .oc-text-body {
    @apply tw-text-base;
  }
  .oc-text-body-strong {
    @apply tw-text-base tw-font-medium;
  }
  .oc-text-caption {
    @apply tw-text-sm;
  }
  .oc-text-caption-strong {
    @apply tw-text-sm tw-font-medium;
  }
  .oc-text-caption-small {
    @apply tw-text-xs;
  }

  [data-theme='kiosk-light'],
  [data-theme='kiosk-dark'],
  [data-theme='desktop-dark'] {
    .mat-app-background {
      @apply tw-bg-page;
    }
    --mat-app-text-color: rgb(var(--text-primary));
    --mat-sidenav-content-text-color: rgb(var(--text-primary));
  }
}
/* stylelint-enable */
