@mixin theme($theme) {
  gl-checkbox {
    @apply tw-w-fit tw-flex-col;
  }

  .mat-mdc-checkbox {
    @apply tw-w-fit;

    label {
      white-space: initial;
    }
  }

  .gl-inline-checkbox {
    @apply tw-mr-4 tw-inline-flex;
  }

  [data-theme='kiosk-light'] gl-checkbox,
  [data-theme='kiosk-dark'] gl-checkbox {
    .mat-mdc-checkbox {
      // This MDC variable controls both the width and height of the entire visual layer of the checkbox
      --mdc-checkbox-state-layer-size: 64px;

      // This custom variable controls the width and height of the check box
      --gl-checkbox-state-size: 36px;

      // This custom variable defines the checkbox padding
      --gl-checkbox-padding: calc((var(--mdc-checkbox-state-layer-size) - var(--gl-checkbox-state-size)) / 2);

      .mdc-checkbox {
        flex: 0 0 var(--gl-checkbox-state-size);
        width: var(--gl-checkbox-state-size);
        height: var(--gl-checkbox-state-size);
        padding: var(--gl-checkbox-padding);
      }

      .mat-mdc-checkbox-touch-target {
        width: calc(var(--mdc-checkbox-state-layer-size) + 8);
        height: calc(var(--mdc-checkbox-state-layer-size) + 8);
      }

      .mdc-checkbox .mdc-checkbox__background {
        top: var(--gl-checkbox-padding);
        left: var(--gl-checkbox-padding);
        width: var(--gl-checkbox-state-size);
        height: var(--gl-checkbox-state-size);
      }

      &.mat-primary:not(:disabled) {
        // Unselected state variables
        --mdc-checkbox-unselected-focus-icon-color: rgb(var(--border-strong));
        --mdc-checkbox-unselected-icon-color: rgb(var(--border-strong));
        --mdc-checkbox-unselected-focus-state-layer-color: rgb(var(--border-strong));

        // Setting opacity of hover state color to 0 as we do not have hover support in a touchscreen
        --mdc-checkbox-unselected-hover-state-layer-color: rgb(var(--border-strong) 0);
        --mdc-checkbox-unselected-hover-icon-color: rgb(var(--border-strong));
        --mdc-checkbox-unselected-pressed-icon-color: rgb(var(--border-strong));

        // Selected state variables
        --mdc-checkbox-selected-focus-icon-color: rgb(var(--ui-secondary));
        --mdc-checkbox-selected-focus-state-layer-color: rgb(var(--ui-secondary));
        --mdc-checkbox-selected-icon-color: rgb(var(--ui-secondary));
        --mdc-checkbox-selected-pressed-icon-color: rgb(var(--ui-secondary));
        --mdc-checkbox-selected-pressed-state-layer-color: rgb(var(--ui-secondary));

        // Setting opacity of hover state color to 0 as we do not have hover support in a touchscreen
        --mdc-checkbox-selected-hover-state-layer-color: rgb(var(--ui-secondary) 0);
        --mdc-checkbox-selected-hover-icon-color: rgb(var(--ui-secondary));
        --mdc-checkbox-selected-checkmark-color: rgb(var(--text-on-secondary));
      }

      .mdc-label {
        @apply oc-text-body tw-text-primary;
      }

      .mdc-checkbox__checkmark {
        @apply tw-inset-[3px] tw-w-4/5;
      }

      .mdc-checkbox__mixedmark {
        @apply tw-w-4/5 tw-border-2;
      }

      &.mdc-checkbox--disabled {
        --mdc-checkbox-disabled-selected-icon-color: rgb(var(--ui-disabled));
        --mdc-checkbox-disabled-unselected-icon-color: rgb(var(--ui-disabled));

        .mdc-label {
          @apply oc-text-body tw-text-disabled;
        }
      }

      .mdc-checkbox__native-control:enabled:not(:checked, :indeterminate, [data-indeterminate='true'])
        ~ .mdc-checkbox__background {
        @apply tw-border-4 tw-border-strong;
      }

      .mdc-checkbox__native-control[disabled]:not(:checked, :indeterminate, [data-indeterminate='true'])
        ~ .mdc-checkbox__background {
        @apply tw-border-4 tw-border-default;
      }
    }

    .mat-mdc-form-field-hint {
      @apply oc-text-caption tw-text-primary;
    }
  }
}
