@mixin build($theme) {
  // Flex classes
  // Can be applied to children of a Stack component, or the Stack component itself
  gl-stack {
    .gl-flex-1,
    &.gl-flex-1 {
      @apply tw-flex-1;
    }

    .gl-flex-auto,
    &.gl-flex-auto {
      @apply tw-flex-auto;
    }

    .gl-flex-none,
    &.gl-flex-none {
      @apply tw-flex-none;
    }

    .gl-flex-shrink-0,
    &.gl-flex-shrink-0 {
      @apply tw-flex-shrink-0;
    }

    .gl-flex-grow-0,
    &.gl-flex-grow-0 {
      @apply tw-flex-grow-0;
    }
  }
}
