@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  .mat-badge-content,
  .mat-badge-default .mat-badge-content {
    background: map.get(gl.$neutral, 100);
  }

  .mat-badge-info .mat-badge-content {
    background: mat.m2-get-color-from-palette($primary);
  }

  .mat-badge-success .mat-badge-content {
    background: gl.$success;
  }

  .mat-badge-warning .mat-badge-content {
    background: gl.$warning;
  }

  .mat-badge-critical .mat-badge-content {
    background: gl.$critical;
  }

  .mat-badge-medium {
    .mat-badge-content {
      border-radius: 0.5rem;
      height: auto;
      line-height: 1rem;
      padding: 0 0.25rem;
      width: auto;
    }

    &.mat-badge-after .mat-badge-content {
      right: -1rem;
    }

    &.mat-badge-above .mat-badge-content {
      top: -0.5rem;
    }
  }
}
