@mixin build($theme) {
  .oc-container-xl-space {
    @apply tw-container tw-mx-auto tw-max-w-screen-xl tw-px-2 tw-pb-2 md:tw-px-8 md:tw-pb-8;
  }

  .oc-container-fluid {
    @apply tw-mx-auto tw-px-2 tw-pb-2 md:tw-px-8 md:tw-pb-8;
  }

  .oc-container-full-width {
    @apply tw-mx-auto;

    .oc-ui-header-page {
      @apply tw-px-2 md:tw-px-8;
    }
  }
}
