@use 'sass:map';
@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  .gl-filters {
    .mat-expansion-panel-body {
      @apply tw-pb-0;
    }
  }

  gl-filters {
    .mat-mdc-card-title {
      @apply oc-text-body tw-font-normal tw-text-azure-contrast-600;
    }
  }

  .oc-filter-container {
    @apply tw-bg-surface-300 tw-px-4 tw-pt-4;

    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0;
      padding-bottom: 0.6rem;
    }
  }
}
