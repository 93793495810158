@use 'sass:map';
@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  $foreground: map.get($theme, foreground);

  .gl-form-container,
  .oc-form-container {
    @apply sm:tw-flex sm:tw-flex-wrap sm:tw-gap-x-2 md:tw-gap-x-4;
  }

  .gl-field-marker::after {
    @apply tw-ml-1;

    color: mat.m2-get-color-from-palette($foreground, base);
    content: '\007E';
  }

  .mat-mdc-checkbox-disabled.gl-field-marker::after {
    color: mat.m2-get-color-from-palette($foreground, disabled-text);
  }

  .mat-form-field-disabled {
    .gl-field-marker::after {
      color: mat.m2-get-color-from-palette($foreground, disabled-text);
    }
  }

  .oc-form-no-hint {
    .mat-form-field-wrapper {
      @apply tw-pb-0;
    }
  }
}
