@use 'sass:map';
@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);

  // height of form field + subscript wrapper
  $height: var(--spacing-14);

  // removing middle border in mdc component
  // tailwind sets border-style to solid for all elements if width is specified
  // material specifies width for border as 2px which creates a border-right in the middle of the form field
  .mdc-notched-outline__notch {
    border-right: 0;
  }

  .mat-form-field-appearance-outline {
    --mdc-outlined-text-field-input-text-color: rgb(var(--text-primary));
    --mat-select-enabled-trigger-text-color: rgb(var(--text-primary));

    // error text color equivalent to tw-text-critical
    --mat-form-field-error-text-color: rgb(var(--text-critical));

    .mat-mdc-text-field-wrapper {
      // mdc migration causes form field to take up height of container due to flex:auto
      // override to maintain height even in a grid
      flex: none;

      // set height of form fields to 44px
      .mat-mdc-form-field-infix {
        @apply tw-py-2.5;

        min-height: unset;
      }

      // center label in form field
      .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 50%;
      }

      // center label when floating
      .mdc-floating-label.mdc-floating-label--float-above {
        top: calc(#{$height} / 2);
      }
    }
  }

  .gl-outline-error .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
  .gl-outline-error .mat-mdc-text-field-wrapper.mdc-text-field--focused .mat-mdc-form-field-flex {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      @apply tw-border-critical;
    }

    .mat-mdc-select-arrow,
    .mdc-floating-label {
      @apply tw-text-critical;
    }

    input:focus {
      caret-color: rgb(var(--text-critical));
    }
  }

  [data-theme='desktop-dark'] gl-date-range-selector {
    .mat-mdc-form-field-flex {
      height: 2.75rem;
    }
  }

  // textarea should not center placeholder/label
  gl-textarea {
    .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex .mat-mdc-floating-label {
        @apply tw-top-6;
      }

      .mdc-floating-label.mdc-floating-label--float-above {
        top: calc(#{$height} / 2);
      }
    }
  }

  gl-input {
    // icon prefix and suffix increase input height, keep font size but reduce space
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        @apply tw-p-2.5;
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: mat.m2-get-color-from-palette(gl.$surface, '300-contrast') !important;
    transition: background-color 5000s;
  }

  .oc-form-group {
    .mat-mdc-form-field.mat-form-field-appearance-outline:first-child {
      .mdc-notched-outline__trailing {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .mat-mdc-form-field.mat-form-field-appearance-outline:last-of-type {
      .mdc-notched-outline__leading {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }

    .mat-mdc-form-field.mat-form-field-appearance-outline:not(:first-child, :last-of-type) {
      .mdc-notched-outline__trailing,
      .mdc-notched-outline__leading {
        border-radius: 0;
      }
    }
  }

  .gl-form-group {
    > :first-child {
      .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    > :last-child {
      .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }

    > :not(:first-child, :last-child) {
      .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
      .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
        border-radius: 0;
      }
    }
  }

  // set hint/error wrapper to relative as absolutely positioned multiline hint and error messages overlap with elements below
  .mat-mdc-form-field-subscript-wrapper {
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      @apply tw-relative;

      .mat-mdc-form-field-error {
        @apply tw-flex;

        gl-icon {
          @apply tw-mr-1;
        }
      }
    }
  }

  [data-theme='desktop-dark'] .mat-mdc-form-field-subscript-wrapper {
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      margin-top: -20px;
    }
  }

  [data-theme='desktop-dark'] .mat-mdc-form-field-hint-spacer:only-child {
    height: var(--caption-line-height);
  }

  [data-theme='kiosk-light'] .mat-mdc-form-field-subscript-wrapper,
  [data-theme='kiosk-dark'] .mat-mdc-form-field-subscript-wrapper {
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      @apply oc-text-caption;
      margin-top: -30px;
    }

    .mat-mdc-form-field-hint-spacer:only-child {
      height: var(--caption-line-height);
    }
  }

  .gl-select-input {
    .gl-select-input-first .mat-form-field-appearance-outline .mdc-notched-outline__trailing {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .gl-select-input-last.mat-form-field-appearance-outline .mdc-notched-outline__leading {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .gl-select-input-first {
      .mdc-text-field--focused {
        .mdc-notched-outline__notch {
          @apply tw-border-2;
        }
      }

      .mdc-notched-outline__notch {
        border-top: 1px solid rgb(255 255 255 / 38%);
      }
    }
  }

  [data-theme='kiosk-light'] .mat-mdc-form-field.mat-form-field-appearance-outline,
  [data-theme='kiosk-dark'] .mat-mdc-form-field.mat-form-field-appearance-outline {
    --mdc-outlined-text-field-disabled-input-text-color: rgb(var(--text-disabled));
    --mdc-outlined-text-field-disabled-outline-color: rgb(var(--border-default));
    --mdc-outlined-text-field-hover-outline-color: rgb(var(--border-strong));

    // padding in rounded corners
    .mat-mdc-text-field-wrapper {
      @apply tw-px-4;
    }

    // determines height of form field
    .mat-mdc-text-field-wrapper,
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        @apply tw-px-0 tw-py-2.5;

        .mat-mdc-input-element:not([disabled])::placeholder {
          @apply tw-text-secondary;
        }

        .mat-mdc-input-element::placeholder {
          @apply tw-text-disabled;
        }
      }
    }
  }

  [data-theme='kiosk-light'] .mdc-text-field--outlined:not(.mdc-text-field--disabled),
  [data-theme='kiosk-light'] .mdc-text-field--outlined,
  [data-theme='kiosk-dark'] .mdc-text-field--outlined:not(.mdc-text-field--disabled),
  [data-theme='kiosk-dark'] .mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-color: rgb(var(--border-strong));
    --mdc-outlined-text-field-focus-outline-color: rgb(var(--border-focus));
    --mdc-outlined-text-field-error-outline-color: rgb(var(--border-critical));
    --mdc-outlined-text-field-error-focus-outline-color: rgb(var(--border-critical));
    --mdc-outlined-text-field-error-hover-outline-color: rgb(var(--border-critical));
    --mdc-outlined-text-field-focus-outline-width: 3px;

    @apply oc-text-body;

    // suffix and prefix styling

    .mat-mdc-form-field-text-prefix,
    .mat-mdc-form-field-text-suffix {
      @apply tw-text-secondary;
    }

    // border width and rounding
    .mdc-notched-outline {
      --mdc-outlined-text-field-container-shape: 1rem;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply tw-border-2;
      }

      .mdc-notched-outline__notch {
        @apply tw-border-t-2;

        border-top-style: solid;
      }
    }
  }
}
