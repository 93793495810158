@use 'sass:math';
@use 'sass:map';
@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities/' as util;

@mixin theme($theme) {
  // Boost font size

  $target-height: 30px;
  $font-size: 12px;
  $vertical-padding: math.div($target-height - $font-size, 2);
  $handset-target-height: 40px;
  $handset-font-size: 18px;
  $handset-vertical-padding: math.div($handset-target-height - $handset-font-size, 2);
  $tooltip-arrow-width: 8px;
  $tooltip-arrow-height: 8px;

  .mdc-tooltip {
    .mdc-tooltip__surface {
      @include util.elevation-popover;

      font-size: $font-size;
      padding-bottom: $vertical-padding;
      padding-top: $vertical-padding;
    }

    .mdc-tooltip-handset {
      font-size: $handset-font-size;
      padding-bottom: $handset-vertical-padding;
      padding-top: $handset-vertical-padding;
    }
  }

  .mat-mdc-tooltip-panel-above,
  .mat-mdc-tooltip-panel-below,
  .mat-mdc-tooltip-panel-left,
  .mat-mdc-tooltip-panel-right {
    overflow: visible;

    .mdc-tooltip {
      overflow: visible;

      &::before {
        @include util.elevation-popover;

        content: '';
        display: inline-block;
        position: absolute;
      }
    }
  }

  .mat-mdc-tooltip-panel-above,
  .mat-mdc-tooltip-panel-below {
    .mdc-tooltip {
      &::before {
        height: $tooltip-arrow-height;
        left: 50%;
        transform: translateX(-50%);
        width: $tooltip-arrow-width;
      }
    }
  }

  .mat-mdc-tooltip-panel-left,
  .mat-mdc-tooltip-panel-right {
    .mdc-tooltip {
      &::before {
        height: $tooltip-arrow-width;
        top: 50%;
        transform: translateY(-50%);
        width: $tooltip-arrow-height;
      }
    }
  }

  .mat-mdc-tooltip-panel-above {
    .mdc-tooltip {
      &::before {
        top: unset;
        bottom: -$tooltip-arrow-height;
        clip-path: polygon(100% 0, 0 0, 50% 100%);
      }
    }
  }

  .mat-mdc-tooltip-panel-below {
    .mdc-tooltip {
      &::before {
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        top: -$tooltip-arrow-height;
      }
    }
  }

  .mat-mdc-tooltip-panel-left {
    .mdc-tooltip {
      &::before {
        left: unset;
        clip-path: polygon(100% 50%, 0 0, 0 100%);
        right: -$tooltip-arrow-height;
      }
    }
  }

  .mat-mdc-tooltip-panel-right {
    .mdc-tooltip {
      &::before {
        clip-path: polygon(100% 0, 0 50%, 100% 100%);
        left: -$tooltip-arrow-height;
      }
    }
  }
}
