@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  .oc-mat-menu-panel-override {
    max-width: inherit !important;

    .mat-mdc-menu-content:not(:empty) {
      @apply tw-pb-0 tw-pt-0;
    }
  }
}
