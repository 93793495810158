@use '../utilities/' as util;

@mixin theme($theme) {
  // Input styling
  [data-theme='desktop-dark'] gl-date-picker {
    .mat-mdc-form-field-flex {
      @apply tw-h-11;
    }
  }

  [data-theme='kiosk-light'] gl-date-picker,
  [data-theme='kiosk-dark'] gl-date-picker {
    .mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
      @apply tw-pr-0;
    }

    .mat-datepicker-toggle {
      --mat-datepicker-toggle-icon-color: rgb(var(--text-primary));

      .mat-mdc-icon-button svg {
        @apply tw-h-6 tw-w-6;
        justify-self: center;
      }
    }

    .mat-datepicker-toggle-active {
      --mat-datepicker-toggle-active-state-icon-color: rgb(var(--bg-primary));
    }
  }

  // Calendar styling
  [data-theme='desktop-dark'].mat-datepicker-content {
    @include util.elevation-popover;
  }

  [data-theme='kiosk-light'] .mat-datepicker-content,
  [data-theme='kiosk-dark'] .mat-datepicker-content {
    --mat-datepicker-calendar-date-hover-state-background-color: transparent;
    --mat-datepicker-calendar-date-selected-state-background-color: rgb(var(--bg-primary));
    --mat-datepicker-calendar-date-today-outline-color: rgb(var(--border-default));
    --mat-datepicker-calendar-date-today-selected-state-outline-color: rgb(var(--border-default));
    --mat-datepicker-calendar-date-focus-state-background-color: rgb(var(--bg-primary) / 12%);
    --mat-datepicker-calendar-date-selected-state-text-color: rgb(var(--text-on-primary));
    --mat-datepicker-calendar-date-text-color: rgb(var(--text-primary));
    --mat-icon-button-state-layer-color: rgb(var(--text-primary));

    @apply tw-rounded-2xl tw-bg-surface-popover;

    th {
      @apply tw-text-secondary;

      // Same as oc-text-body without line-height
      font-size: (var(--body-font-size));
    }

    td {
      @apply tw-text-primary;

      font-size: (var(--body-font-size));
    }

    .mat-calendar-body-label {
      @apply tw-text-primary;

      font-size: (var(--body-font-size));
    }

    // Header
    .mat-calendar-controls {
      .mat-mdc-button {
        --mdc-text-button-label-text-color: rgb(var(--text-primary));
      }

      .mdc-button__label {
        font-size: (var(--body-font-size));
      }

      .mat-calendar-arrow {
        @apply tw-h-1.5 tw-w-7;

        fill: rgb(var(--text-secondary));
      }

      .mat-calendar-previous-button {
        @apply tw-mr-4;
      }

      .mdc-icon-button.mat-mdc-icon-button {
        &.mat-mdc-button-base {
          --mdc-icon-button-state-layer-size: 48px;
          @apply tw-text-secondary;

          width: var(--mdc-icon-button-state-layer-size);
          height: var(--mdc-icon-button-state-layer-size);
        }

        .mat-mdc-button-persistent-ripple,
        .mat-mdc-button-ripple {
          top: -10px;
          left: -10px;
          bottom: unset;
          right: unset;
        }
      }
    }

    .mat-calendar-body-cell-container .mat-calendar-body-disabled .mat-calendar-body-cell-content {
      @apply tw-text-disabled;
    }
  }
}
