/* stylelint-disable */
@use '@angular/material' as mat;
@use 'sass:map';
@use 'palette';
@use 'typography';

// Background palette for dark themes.
$dark-theme-background-palette: (
  status-bar: black,
  app-bar: map.get(palette.$surface, 400),
  background: map.get(palette.$surface, 700),
  hover: rgba(white, 0.1),
  card: map.get(palette.$surface, 400),
  dialog: map.get(palette.$surface, 400),
  disabled-button: rgba(white, 0.04),
  raised-button: map.get(mat.$m2-grey-palette, 800),
  focused-button: rgba(white, 0.12),
  selected-button: map.get(mat.$m2-grey-palette, 900),
  selected-disabled-button: map.get(mat.$m2-grey-palette, 800),
  disabled-button-toggle: black,
  unselected-chip: map.get(mat.$m2-grey-palette, 700),
  disabled-list-option: black,
  tooltip: map.get(palette.$surface, 300),
);

// Foreground palette for dark themes.
$dark-theme-foreground-palette: (
  base: white,
  divider: rgba(white, 0.12),
  dividers: rgba(white, 0.12),
  disabled: rgba(white, 0.5),
  disabled-button: rgba(white, 0.3),
  disabled-text: rgba(white, 0.5),
  elevation: black,
  hint-text: rgba(white, 0.5),
  secondary-text: rgba(white, 0.5),
  icon: white,
  icons: white,
  text: white,
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);

$dark-palette: mat.m2-define-dark-theme(
  (
    color: (
      primary: palette.$azure,
      accent: palette.$seafoam,
      warn: palette.$scarlet,
    ),
    typography: typography.$typography,
    density: 0,
  )
);

$dark-palette-bg: map.merge($dark-palette, 'color', 'background', $dark-theme-background-palette);

$dark-theme: map.merge($dark-palette-bg, 'color', 'foreground', $dark-theme-foreground-palette);
/* stylelint-enable */
