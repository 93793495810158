@mixin status-border {
  content: '';
  height: 100%;
  inset: 0;
  position: absolute;
  width: 0.25rem;
}

@mixin color-border {
  border-color: rgb(0 0 0 / 40%) !important;
}

@mixin color-border-strong {
  border-color: rgb(0 0 0 / 100%) !important;
}
