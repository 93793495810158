@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.m2-get-color-config($theme);
  $foreground: map.get($config, foreground);
  $divider-color: mat.m2-get-color-from-palette($foreground, divider);

  .gl-section-divider {
    border-bottom: 1px solid $divider-color;
  }

  .gl-section-group {
    > :last-child {
      .gl-section-divider {
        @apply tw-border-0;
      }
    }

    > *:not(:first-child) {
      > div {
        @apply tw-mt-4;
      }
    }
  }

  .gl-section-form {
    @apply sm:tw-flex sm:tw-flex-wrap sm:tw-gap-x-2 md:tw-gap-x-4;
  }
}
