/* stylelint-disable selector-class-pattern */
@use 'sass:map';
@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities/' as util;

@mixin theme($theme) {
  $is-dark-theme: map.get($theme, is-dark);

  .mdc-snackbar.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      @include util.elevation-overlay;
    }

    .mat-mdc-snack-bar-label {
      @apply tw-text-primary;
    }

    &.mdc-snackbar {
      @apply tw-mt-20 tw-min-w-0 tw-max-w-full;
    }

    &::before,
    &.warning::before,
    &.critical::before,
    &.success::before {
      @apply tw-absolute tw-inset-0 tw-z-1 tw-h-full tw-w-2;

      content: '';
    }

    &::before {
      @apply tw-bg-azure;
    }

    &.warning::before {
      @apply tw-bg-warning;
    }

    &.critical::before {
      @apply tw-bg-critical;
    }

    &.success::before {
      @apply tw-bg-success;
    }

    .gl-min-height-10 {
      min-height: 3.75rem;
    }
  }
}
/* stylelint-enable selector-class-pattern */
