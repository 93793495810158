@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  gl-tag {
    @apply tw-inline-flex;

    height: fit-content;
  }

  .gl-tag {
    @apply tw-mr-2 tw-inline-block tw-rounded-full tw-px-2 tw-py-0.5 tw-text-center tw-font-normal;

    font-size: var(--caption-sm-font-size);
    line-height: var(--caption-sm-line-height);
  }

  [data-theme='desktop-dark'] {
    .gl-tag-filled {
      &.gl-tag-default {
        @apply tw-bg-neutral-100 tw-text-neutral-contrast-100;
      }

      &.gl-tag-info {
        @apply tw-bg-azure-500 tw-text-azure-contrast-500;
      }

      &.gl-tag-success {
        @apply tw-bg-success tw-text-success-contrast;
      }

      &.gl-tag-warning {
        @apply tw-bg-warning tw-text-warning-contrast;
      }

      &.gl-tag-warning-strong {
        @apply tw-bg-warning-strong tw-text-warning-contrast;
      }

      &.gl-tag-critical {
        @apply tw-bg-critical tw-text-critical-contrast;
      }

      &.gl-tag-white {
        background-color: rgb(var(--neutral-alt-900));
        @apply tw-text-neutral-contrast-100;
      }
    }
  }

  [data-theme='kiosk-light'],
  [data-theme='kiosk-dark'] {
    .gl-tag-filled {
      // Weak colors
      &.gl-tag-default {
        @apply tw-bg-neutral tw-text-on-neutral;
      }

      &.gl-tag-info {
        @apply tw-bg-info tw-text-on-info;
      }

      &.gl-tag-success {
        @apply tw-bg-success tw-text-on-success;
      }

      &.gl-tag-warning {
        @apply tw-bg-warning tw-text-on-warning;
      }

      &.gl-tag-critical {
        @apply tw-bg-critical tw-text-on-critical;
      }

      // Strong colors
      &.gl-tag-default-strong {
        @apply tw-bg-neutral-strong tw-text-on-neutral-strong;
      }

      &.gl-tag-info-strong {
        @apply tw-bg-info-strong tw-text-on-info-strong;
      }

      &.gl-tag-success-strong {
        @apply tw-bg-success-strong tw-text-on-success-strong;
      }

      &.gl-tag-warning-strong {
        @apply tw-bg-warning-strong tw-text-on-warning-strong;
      }

      &.gl-tag-critical-strong {
        @apply tw-bg-critical-strong tw-text-on-critical-strong;
      }
    }
  }

  [data-theme='kiosk-light'] .gl-tag-filled {
    &.gl-tag-white {
      background-color: rgb(var(--neutral-33));
      @apply tw-text-on-neutral;
    }
  }

  [data-theme='kiosk-dark'] .gl-tag-filled {
    &.gl-tag-white {
      background-color: rgb(var(--neutral-900));
      @apply tw-text-on-neutral-strong;
    }
  }
}
